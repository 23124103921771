import Permissions from '@hellochef/shared-js/enums/Permissions';

export default {
  'Requested': {
    dotClass: 'bg-dark text-dark',
    btnClass: 'btn-dark',
    permission: '',
    nextPossibleStatuses: [
      'In Progress',
    ],
    needsValidation: false,
  },
  'In Progress': {
    dotClass: 'bg-primary text-primary',
    btnClass: 'btn-primary',
    permission: Permissions.ARTICLES_REQUISITIONS_IN_PROGRESS_STATUS_UPDATE,
    nextPossibleStatuses: [
      'Sample Requested',
      'Rejected - Procurement',
    ],
    needsValidation: false,
  },
  'Sample Requested': {
    dotClass: 'bg-info text-info',
    btnClass: 'btn-info',
    permission: Permissions.ARTICLES_REQUISITIONS_SAMPLE_REQUESTED_STATUS_UPDATE,
    nextPossibleStatuses: [
      'Approved',
      'Rejected - Recipe Developer',
    ],
    needsValidation: true,
  },
  'Approved': {
    dotClass: 'bg-success text-success',
    btnClass: 'btn-success',
    permission: Permissions.ARTICLES_REQUISITIONS_APPROVED_STATUS_UPDATE,
    nextPossibleStatuses: [
      'Reviewed',
    ],
    needsValidation: false,
  },
  'Rejected': {
    dotClass: 'bg-danger text-danger',
    btnClass: 'btn-danger',
    permission: Permissions.ARTICLES_REQUISITIONS_REJECTED_STATUS_UPDATE,
    nextPossibleStatuses: [
      'In Progress',
    ],
    needsValidation: false,
  },
  'Reviewed': {
    dotClass: 'bg-lime text-lime',
    btnClass: 'btn-lime',
    permission: Permissions.ARTICLES_REQUISITIONS_REVIEWED_STATUS_UPDATE,
    nextPossibleStatuses: [],
    needsValidation: false,
  },
  'Rejected - Procurement': {
    dotClass: 'bg-danger text-danger',
    btnClass: 'btn-danger',
    permission: Permissions.ARTICLES_REQUISITIONS_REJECTED_PROCUREMENT_STATUS_UPDATE,
    nextPossibleStatuses: [
      'In Progress',
    ],
    needsValidation: false,
  },
  'Rejected - Recipe Developer': {
    dotClass: 'bg-danger text-danger',
    btnClass: 'btn-danger',
    permission: Permissions.ARTICLES_REQUISITIONS_REJECTED_RECIPE_DEVELOPER_STATUS_UPDATE,
    nextPossibleStatuses: [
      'In Progress',
    ],
    needsValidation: false,
  },
  default: {
    dotClass: 'bg-info text-info',
    btnClass: 'btn-info',
    permission: '',
    nextPossibleStatuses: [],
    needsValidation: false,
  },
};
