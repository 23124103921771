<template>
  <div class="container">
    <alert
      v-if="feature"
      type="info"
      message="Revamped feature! Request new articles for ingredients &amp; track their progress."
      @close="feature = false"></alert>
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} article
        requisitions</span>
      <span v-else class="text-truncate">Found {{meta.total}} article requisitions that match '{{query}}'</span>
      <div class="d-flex ml-auto">
        <select
          v-model="filterStatus"
          class="form-control custom-select w-auto"
          @change="refresh">
          <option value="">All statuses</option>
          <option
            v-for="status in statuses"
            :key="status"
            :value="status">
            {{status}}
          </option>
        </select>
        <router-link
          v-if="can(uiPermissions.ARTICLES_REQUISITIONS_CREATE)"
          to="/article-requisitions/new"
          class="btn btn-primary ml-2">
          Request A New Article
        </router-link>
      </div>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th>Ingredient</th>
                  <th>Brand</th>
                  <th>Portion Size</th>
                  <th>Requested By</th>
                  <th>Status</th>
                  <th class="text-center">Created at</th>
                  <th class="w-1">Last updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span
                      v-if="item.photo"
                      class="avatar"
                      :style="`background-image: url(${item.photo.url});`"></span>
                    <span
                      v-else
                      class="avatar"
                      :style="`background-image: url(${identicon(item.id)});`"></span>
                  </td>
                  <td>
                    <div>
                      <router-link :to="`/article-requisitions/${item.id}`">{{item.ingredient.name}}</router-link>
                    </div>
                  </td>
                  <td>{{articleBrand(item)}}</td>
                  <td>{{item.article_portion_size}} {{item.article_uom}}</td>
                  <td>{{item.requested_by_name}}</td>
                  <td>
                    <div v-if="ArticleRequisitionStatus[item.status]">
                      <i
                        class="fe fe-circle rounded-circle mr-1"
                        :class="ArticleRequisitionStatus[item.status].dotClass"></i>
                      {{item.status}}
                    </div>
                    <div v-else>
                      <i
                        class="fe fe-circle rounded-circle b mr-1"
                        :class="ArticleRequisitionStatus['default'].dotClass"></i>
                      {{upperFirst(item.status)}}
                    </div>
                  </td>
                  <td class="text-center">
                    {{moment.utc(item.created_at).local().fromNow()}}
                  </td>
                  <td>
                    {{moment.utc(item.updated_at).local().fromNow()}}
                  </td>
                  <td class="text-center">
                    <div v-if="can([uiPermissions.ARTICLES_REQUISITIONS_UPDATE, uiPermissions.ARTICLES_REQUISITIONS_DUPLICATE, uiPermissions.ARTICLES_REQUISITIONS_DELETE])" class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        class="icon"><i class="fe fe-more-vertical"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <router-link
                          v-if="can(uiPermissions.ARTICLES_REQUISITIONS_UPDATE)"
                          :to="`/article-requisitions/${item.id}`"
                          class="dropdown-item">
                          <i class="dropdown-icon fe fe-edit"></i> Edit
                        </router-link>
                        <button
                          v-if="can(uiPermissions.ARTICLES_REQUISITIONS_DUPLICATE)"
                          type="button"
                          class="dropdown-item"
                          @click="handleDuplicate(item)">
                          <i class="dropdown-icon fe fe-copy"></i> Duplicate
                        </button>
                        <div v-if="can(uiPermissions.ARTICLES_REQUISITIONS_DELETE)" class="dropdown-divider"></div>
                        <button
                          v-if="can(uiPermissions.ARTICLES_REQUISITIONS_DELETE)"
                          type="button"
                          class="dropdown-item"
                          @click="handleDelete(item)">
                          <i class="dropdown-icon fe fe-trash"></i> Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="6">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {articleRequisitions} from '@/services';
import ArticleRequisitionStatus from '@/assets/enums/ArticleRequisitionStatus';

export default {
  components: {
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      ArticleRequisitionStatus,
      feature: true,
      filterStatus: '',
    };
  },
  computed: {
    statuses() {
      return Object.keys(ArticleRequisitionStatus).filter(s => s !== 'default');
    },
  },
  methods: {
    fetchData(page) {
      return articleRequisitions.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
        status: this.filterStatus,
      });
    },
    async handleDelete(item) {
      if (window.confirm('Deleting an article requisition cannot be undone. Are you sure you want to do this?')) {
        await articleRequisitions.deleteById(item.id);
        this.refresh();
      }
    },
    async handleDuplicate(item) {
      // create new and copy content
      this.$router.push(`/article-requisitions/new?duplicate=${item.id}`);
    },
    articleBrand(requisition) {
      if (requisition.article_data?.brand) {
        return requisition.article_data.brand.name;
      }

      if (requisition.custom_brand_name) {
        return requisition.custom_brand_name;
      }

      return requisition.brand.name;
    },
  },
};
</script>
